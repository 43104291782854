const ACT_TYPE = {
  LOADING:1,
  ERROR:2,
  CONNECTED:3,
  DEVICE_INPUT:4,
  MATCHED_FORMS_DATA:5,
  ALL_FORMS_DATA:6,
  ENCRYPT_SECRET:7,
  MASTER_KEY:8,
  SAVE_FORM_DATA:10,
  SESSION_END:11,
  ENCRYPT_SELECT_KEY:12,
  DECRYPT_SELECT_KEY:13,
  ENCRYPT_SEND_RESULT:14,
  DECRYPT_SEND_RESULT:15,
  IMPORT_FORM_DATA:16
};

export default ACT_TYPE;